<template>
  <img class="LifeskillSunImage" :src="getLifeskillSunImage" alt="">
</template>

<script>
    import { getCategoryIdFromLifeskillId } from '@/utils';
    import sun1Image from './assets/images/sun1.svg';
    import sun2Image from './assets/images/sun2.svg';
    import sun3Image from './assets/images/sun3.svg';
    import sun4Image from './assets/images/sun4.svg';
    import sun5Image from './assets/images/sun5.svg';
    import sun6Image from './assets/images/sun6.svg';
    import sun7Image from './assets/images/sun7.svg';
    import dwarfSun1Image from './assets/images/sunDwarf1.svg';
    import dwarfSun2Image from './assets/images/sunDwarf2.svg';
    import dwarfSun3Image from './assets/images/sunDwarf3.svg';
    import dwarfSun4Image from './assets/images/sunDwarf4.svg';
    import dwarfSun5Image from './assets/images/sunDwarf5.svg';
    import dwarfSun6Image from './assets/images/sunDwarf6.svg';
    import dwarfSun7Image from './assets/images/sunDwarf7.svg';

    export default {
        props: {
            lifeskillId: {
                type: String,
                required: true,
            },
            dwarfStar: {
                type: Boolean,
                required: false,
            }
        },
        computed: {
            getLifeskillSunImage() { 
                const categoryName = getCategoryIdFromLifeskillId(this.lifeskillId);
                if(categoryName === 'brain'){
                    return this.dwarfStar? dwarfSun1Image : sun1Image;
                } else if(categoryName === 'knowledge'){
                    return this.dwarfStar? dwarfSun3Image : sun3Image;
                } else if(categoryName==='mind'){
                    return this.dwarfStar? dwarfSun4Image : sun4Image;
                } else if(categoryName === 'body'){
                    return this.dwarfStar? dwarfSun2Image : sun2Image;
                } else if(categoryName === 'soul'){
                    return this.dwarfStar? dwarfSun5Image : sun5Image;
                } else if(categoryName === 'career'){
                    return this.dwarfStar? dwarfSun6Image : sun6Image;
                } else if(categoryName === 'leisure'){
                    return this.dwarfStar? dwarfSun7Image : sun7Image;
                }
                return sun1Image;
            },
        }
    }

</script>

<style lang="scss" scoped>
    .LifeskillSunImage {
        position: absolute;
        width: 3.5em;
        height: 3.5em;

        @media only screen and (max-width: 600px) {
        position: absolute;
        width: 10.5vw;
        height: 13vw;
        }
        @media only screen and (min-width: 600px) and (max-width: 1200px) {
        width: 7vw;
        height: 7vw;
        }
    }
</style>