<template>
  <div class="MissionTotalProgress">
    <div class="progress-bar-wrapper">
      <div class="progress-bar" :style="{ width: progress + '%' }" />
    </div>
    <!-- <div v-if="selectedTeamId !== '0'" class="progress-bar-wrapper">
      <div class="progress-bar teamProgress" :style="{ width: teamProgress + '%' }" />
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true,
    },
    teamProgress: {
      type: Number,
      required: true,
    },
  },
  computed: {
    selectedTeamId() {
      return this.$store.state.moduleApp.memolife.selectedTeamId
    },
  },
}
</script>

<style lang="scss" scoped>
.MissionTotalProgress {
  width: 90%;
}
.progress-bar-wrapper {
  margin: 7.5px 0;
  background: #05b9fa1c;
}
.progress-bar {
  width: 0;
  height: 6px;
  background-color: #f4fcff;
  border-top-left-radius: 50% 50px;
  border-bottom-left-radius: 50% 50px;
  border-top-right-radius: 50% 50px;
  border-bottom-right-radius: 50% 50px;
}
.teamProgress {
  background-color: #05b8fa;
}
</style>
