<template>
  <div class="SkillsMissionDetails">
    <div class="missionMonths" :class="{ missionMonths3: getMissionLifeskillsLength() < 4 }">
      <div v-for="index in getMissionLifeskillsLength()" :key="index">
        <div class="month"> {{ index }} .mnd </div>
      </div>
    </div>
    <div class="missionMonthBulletPointsContainerMemolifestyle missionMonthBulletPointsScrollAnim">
      <img class="missionMonthBulletPointsImage" :src="getMissionDotsImage()" alt="" />
    </div>

    <div class="missionMonthContainer">
      <!-- NOTE: We curently have no separate courses for missions -->
      <!-- <div v-if="mission.courses" @click="gotoCourse(false, 'top-10')" class="missionMonthTitlesContainer missionMonthTitlesContainer1">
          <div>
            <h3 class="missionMonthTitle"> Bli fremtidsklar </h3>
            <p class="missionMonthSubtitle"> Topp fremtidsferdigheter </p>
          </div>
          <div class="missionSunImageNumContainer">
            <LifeskillSunImage class="missionSunImage" :lifeskill-id="'41'" />
            <p class="missionSunNum">x</p>
            <LifeskillCircularProgress slot="center" :lifeskill-id="'top-10'" :get-course-stats="true" class="lifeskillCircularProgress" />
          </div>
        </div> -->

      <div
        v-for="(lifeskill, index) in mission.lifeskills"
        :key="index"
        class="missionMonthTitlesContainer missionMonthTitlesContainer1"
        :class="{ selectable: enableLifeskillNavigation }"
        @click="gotoCourse(true, lifeskill)"
      >
        <div>
          <h3 class="missionMonthTitle">{{ getMissionTitles(lifeskill) }}</h3>
          <p class="missionMonthSubtitle"
            >{{ $t(`dashboard.general.lifeskillLabels.${lifeskill}`) }} ({{ lifeskill }})</p
          >
        </div>
        <div class="missionSunImageNumContainer">
          <LifeskillSunImage class="missionSunImage" :lifeskill-id="lifeskill" />
          <p class="missionSunNum">{{ lifeskill }}</p>
          <MissionLifeskillCircularProgress
            slot="center"
            :lifeskill-id="lifeskill"
            :team-id="teamId"
            class="lifeskillCircularProgress"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MissionLifeskillCircularProgress from './MissionLifeskillCircularProgress'
import LifeskillSunImage from '../UniverseSection/LifeskillSunImage.vue'

import { getSkillMissionById, getMissionTitles } from '@/store/modules/module-app/missions.js'
const missionSectionImages = require.context('./assets/images/', true, /\.(svg)$/)

export default {
  components: { MissionLifeskillCircularProgress, LifeskillSunImage },
  props: {
    teamId: {
      type: Number,
    },
    missionId: {
      type: String,
      required: true,
    },
    enableLifeskillNavigation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mission() {
      return getSkillMissionById(this.missionId)
    },
  },
  methods: {
    getMissionLifeskillsLength() {
      if (this.mission.courses) {
        return this.mission.lifeskills.length + this.mission.courses.length
      } else {
        return this.mission.lifeskills.length
      }
    },
    getMissionDotsImage() {
      if (this.getMissionLifeskillsLength() === 4) {
        return missionSectionImages('./goal-months-bullet-points-4-dots.svg')
      } else {
        return missionSectionImages('./goal-months-bullet-points-3-dots.svg')
      }
    },
    getMissionTitles(lifeskill) {
      return getMissionTitles(this.missionId, lifeskill)
    },

    gotoCourse(lifeskill, id) {
      if (this.enableLifeskillNavigation) {
        if (lifeskill) {
          this.$router.push({
            name: 'DashboardLifeskillView',
            params: {
              lifeskillId: id,
            },
          })
        } else {
          this.$router.push({
            name: 'CourseHomeView',
            params: {
              courseId: id,
            },
          })
        }
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro');

$text-font: Source Sans Pro;
$camelcase-spacing: 0.02em;
$color-white: #d6d4ef;
$color-gray: #a09dac;

.SkillsMissionDetails {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 2em;
  padding-top: 0;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    flex-direction: row;
    padding: 0;
    width: 90vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    padding: 4vw;
  }
}

.month {
  font-size: 0.95em;

  @media only screen and (max-width: 600px) {
    font-size: 2.2vw;
    width: 11vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 1.5vw;
    width: 10vw;
    display: flex;
    justify-content: center;
  }
}

.missionMonthBulletPointsContainerMemolifestyle {
  height: 29em;
  display: flex;
  align-items: center;
  margin-right: 2em;
  margin-top: 2em;

  @media only screen and (max-width: 600px) {
    display: none;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    height: 57vw;
    margin-right: 2vw;
    margin-top: 2.1vw;
  }
}

.missionMonthBulletPointsImage {
  height: 25.5em;

  @media only screen and (max-width: 600px) {
    display: none;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    height: 47.5vw;
  }
}

.missionMonthTitlesContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em;
  background-color: #1f1b5a62;
  padding-left: 2em;
  width: 35em;
  height: 7em;
  letter-spacing: 0.03em;
  padding-top: 1em;
  padding-bottom: 1em;
  border-radius: 0.3em;

  &.selectable {
    &:hover {
      cursor: pointer;
      background-color: #2a247562;
    }
  }

  @media only screen and (max-width: 600px) {
    margin: 3vw;
    margin-right: 0;
    padding-left: 8vw;
    width: 73vw;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 1vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    margin: 2vw;
    padding-left: 4vw;
    width: 65vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
  }
}

.missionMonthTitle {
  font-size: 1.6em;
  color: $color-white;
  letter-spacing: $camelcase-spacing;
  font-family: $text-font;
  font-weight: 500;
  margin: 0;

  @media only screen and (max-width: 600px) {
    font-size: 4.5vw;
    width: 45vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 3.7vw;
  }
}
.missionMonthSubtitle {
  color: $color-gray;
  font-family: $text-font;
  font-size: 1.1em;
  letter-spacing: $camelcase-spacing;
  font-weight: 500;
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  @media only screen and (max-width: 600px) {
    font-size: 3.5vw;
    width: 50vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 2.1vw;
    margin-top: 1.2vw;
    margin-bottom: 1.2vw;
  }
}

.missionSunImageNumContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 3.5em;

  @media only screen and (max-width: 600px) {
    display: flex;
    padding-right: 10vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    padding-right: 3vw;
  }
}
.missionSunImage {
  height: 4em;

  /deep/ {
    .LifeskillSunImage {
      height: 5em;
      width: 5em;
    }
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    height: 13vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    height: 7.5vw;
  }
}
.missionSunNum {
  position: absolute;
  font-family: 'HelveticaNeue', sans-serif;
  color: rgba(0, 0, 0, 0.452);
  z-index: 1;
  font-size: 1.1em;
  margin: 0;

  @media only screen and (max-width: 600px) {
    display: flex;
    font-size: 3.3vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 2vw;
  }
}
.lifeskillCircularProgress {
  width: 3.6em;
  height: 3.6em;
  @media only screen and (max-width: 600px) {
    position: absolute;
    width: 11.7vw;
    height: 11.7vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    width: 6.8vw;
    height: 6.8vw;
  }
}

.missionMonths {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 5em;
  font-size: 0.7em;
  letter-spacing: 0.05em;
  color: white;
  opacity: 0.5;
  font-family: 'HelveticaNeue', sans-serif;
  margin-right: 2em;
  padding-bottom: 1em;
  padding-top: 0.5em;

  &.missionMonths3 {
    height: 35.75em;
    @media only screen and (min-width: 600px) and (max-width: 1200px) {
      margin-right: 6vw;
      padding-top: 1vw;
      padding-bottom: 0vw;
    }
  }

  @media only screen and (max-width: 600px) {
    font-size: 2.5vw;
    margin-right: 0;
    padding-top: 2vw;
    padding-bottom: 2vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    padding: 0.5vw 4vw;
    width: 15vw;
    height: 61vw;
  }
}
</style>
