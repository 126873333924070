<template>
  <div class="TeamProfilePage">
    <WidthWrapper set-vars-on-parent />
    <div v-if="isLoaded" class="Leaderboards section">
      <div class="titleAndTabsContainer">
        <div class="teamTitleContainer" @click.stop="goToEditTeamPage">
          <h2 class="teamTitle">{{ teamName }}</h2>
          <img class="editButton" src="./TeamsSection/assets/icons/edit_icon.svg" alt="" />
        </div>

        <img class="rankImage" :src="teamIcon" alt="" />

        <div class="tabs">
          <div class="tab" :class="{ selected: tabSelected === 'progress' }" @click="tabSelected = 'progress'">{{
            $t('progress')
          }}</div>
          <div class="tab" :class="{ selected: tabSelected === 'members' }" @click="tabSelected = 'members'">{{
            $t('members')
          }}</div>
        </div>
      </div>

      <TeamProfileLeaderboard v-if="tabSelected === 'members'" :members-list="teamLeaderboardFullsList" />
      <template v-if="tabSelected === 'progress'">
        <FullWidthHorizontalScroll v-if="tabSelected === 'progress'">
          <SkillsMissionItem
            v-for="mission of missions"
            :key="mission.id"
            :mission="mission"
            :selected="true"
            :viewing="false"
            :team-id="teamId"
            :class="{ customSelected: selectedItemKey === null }"
            @click.native="selectedItemKey = null"
          />
          <HabitMissionItem
            v-for="mission of habitMissions"
            :key="mission.id"
            :mission="mission"
            :selected="true"
            :viewing="false"
            :class="{ customSelected: selectedItemKey === mission.id }"
            @click.native="selectedItemKey = mission.id"
          />
        </FullWidthHorizontalScroll>

        <HabitMissionDetails v-if="selectedItemKey" :mission-id="selectedItemKey" />
        <SkillsMissionDetails
          v-else-if="skillsMissionId"
          :mission-id="skillsMissionId"
          :team-id="teamId"
          :enable-lifeskill-navigation="true"
        />
      </template>
    </div>
    <div class="missionsContainer section">
      <!-- {{ teamsList }} -->
    </div>
  </div>
</template>

<translations>
  
  progress: Progress
  progress_no: Fremgang
  members: Members
  members_no: Medlemmer

  braindates: BRAIN DATES
  braindates_no: HJERNEDATES
  member: MEMBER
  member_no: MEDLEM

  achievements: Achievements
  achievements_no: Prestasjoner
  
  
</translations>

<script>
import TeamProfileLeaderboard from './TeamProfileLeaderboard'

import SkillsMissionItem from '../components/MissionsSection/SkillsMissionItem.vue'
import HabitMissionItem from '../components/MissionsSection/HabitMissionItem.vue'
import HabitMissionDetails from '../components/MissionsSection/HabitMissionDetails.vue'
import SkillsMissionDetails from '../components/MissionsSection/SkillsMissionDetails.vue'
import FullWidthHorizontalScroll from '@/modules/dashboard/components/FullWidthHorizontalScroll'
import WidthWrapper from '@/modules/dashboard/components/WidthWrapper'

export default {
  components: {
    TeamProfileLeaderboard,
    SkillsMissionItem,
    HabitMissionItem,
    SkillsMissionDetails,
    HabitMissionDetails,
    FullWidthHorizontalScroll,
    WidthWrapper,
  },
  props: {
    teamId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      tabSelected: 'progress',
      selectedItemKey: null,
    }
  },

  computed: {
    missions() {
      return this.$store.getters['moduleTeam/missions']
    },

    habitMissions() {
      return this.$store.getters['moduleTeam/habitMissions']
    },

    skillsMissionId() {
      return this.missions[0]?.id
    },

    teamName() {
      return this.$store.state.moduleTeam.name
    },

    isLoaded() {
      return this.$store.getters['moduleTeam/isLoaded']
    },

    teamIcon() {
      const primaryTag = this.$store.getters['moduleTeam/primaryTag']

      if (primaryTag === 'Family') {
        return require('./TeamsSection/assets/icons/family-team-icon.svg')
      } else if (primaryTag === 'Friends') {
        return require('./TeamsSection/assets/icons/friends-team-icon.svg')
      } else if (primaryTag === 'Work') {
        return require('./TeamsSection/assets/icons/work-team-icon.svg')
      } else {
        return require('./TeamsSection/assets/icons/other-team-icon.svg')
      }
    },

    teamLeaderboardFullsList() {
      return this.$store.getters['moduleTeam/teamLeaderboardFullsList']
    },
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    goToEditTeamPage() {
      this.$router.push({
        name: 'TeamDetails',
        params: { teamId: this.teamId },
      })
    },

    async fetchData() {
      // const teamId = parseInt(this.teamId)
      await this.$store.dispatch('moduleTeam/load', this.teamId)
      // this.reactive.teamLeaderboardFullsList = this.teamLeaderboardFullsList // testing
    },
  },
}
</script>

<style lang="scss" scoped>
.TeamProfilePage {
  // display: flex;
  // justify-content: center;
  // align-items: flex-start;
  color: white;
  overflow: auto;
  background: #110b30;
}
.rankImage {
  width: 12em;
  height: 12em;
  // border-radius: 6em;
  margin-bottom: 2.5em;
  margin-top: 1em;

  @media (max-width: 620px) {
    margin-bottom: 8vw;
    margin-top: 0;
  }
}
.Leaderboards {
  display: flex;
  flex-direction: column;
  // width: 50em;
  justify-content: center;
  margin-top: 6em;
  margin-bottom: 4em;

  @media (max-width: 620px) {
    // width: 95vw;
    margin-top: 5em;
  }
}

.titleAndTabsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;

  @media (max-width: 620px) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 6vw;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  background-color: #211850;
  border-radius: 1em;
  padding: 0.2em;
  font-size: 0.9em;
}
.tab {
  padding: 0.7em 1.5em;
  border-radius: 1em;
  cursor: pointer;

  &.selected {
    background-color: #3687f7;
  }
}

.teamTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 620px) {
    margin-bottom: 3vw;
  }
  .teamTitle {
    font-size: 1.7em;
    font-weight: 600;

    @media (max-width: 620px) {
      font-size: 6vw;
    }
  }
  .editButton {
    width: 1em;
    height: auto;
    margin-left: 1em;
    margin-top: 0.2em;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    @media only screen and (max-width: 600px) {
      margin-left: 3vw;
      margin-top: 0.6vw;
      width: 3.7vw;
      height: auto;
    }
  }
}

.section {
  position: relative;
  padding-left: calc(var(--widthWrapperSideWidth) * 1px);
  padding-right: calc(var(--widthWrapperSideWidth) * 1px);

  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    padding-left: calc(var(--widthWrapperSideWidth) * 0.3px);
    padding-right: calc(var(--widthWrapperSideWidth) * 0.3px);
  }
}

.missionsContainer {
  display: flex;
  flex-direction: row;
}

.SkillsMissionItem,
.HabitMissionItem {
  &.customSelected {
    border: 2px solid rgba(255, 255, 255, 0.107);
  }
}
</style>
