<template>
  <div class="MissionLifeskillCircularProgress">
    <CircularProgress
      :progress="progress"
      track-color="rgba(255, 255, 255, 0.1)"
      color="rgba(255, 255, 255, 0.6)"
      :stroke-width="3"
    />
  </div>
</template>

<script>
import CircularProgress from '@/components/CircularProgress'

export default {
  components: { CircularProgress },
  props: {
    teamId: {
      type: Number,
    },
    lifeskillId: {
      type: String,
      required: true,
    },
  },
  computed: {
    progress() {
      if (this.teamId) {
        if (this.$store.state.moduleTeam.teamId !== this.teamId) {
          return 0
        }
        return this.$store.getters['moduleTeam/getLifeskillStats'](this.lifeskillId).progress
      } else {
        return this.$store.getters['moduleApp/getLifeskillStats'](this.lifeskillId).progress
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.MissionLifeskillCircularProgress {
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
