<template>
  <div
    class="HabitMissionItem"
    :class="{
      selected,
      viewing: viewingMissionId === mission.id,
      notSelected: !habitMissionBraindates.length,
    }"
    @click="$emit('select', mission.id)"
  >
    <div class="badgeContainer">
      <img class="missionImage" :src="getMissionBadgeImage(mission.id)" alt="" draggable="false" />
      <div class="neurons">
        <img
          class="neuronImage"
          :class="{ active: isNeuron1Active(mission.id) }"
          :src="getMissionNeuronImage(mission.id)"
          alt=""
          draggable="false"
        />
        <img
          class="neuronImage"
          :class="{ active: isNeuron2Active(mission.id) }"
          :src="getMissionNeuronImage(mission.id)"
          alt=""
          draggable="false"
        />
        <img
          class="neuronImage"
          :class="{ active: isNeuron3Active(mission.id) }"
          :src="getMissionNeuronImage(mission.id)"
          alt=""
          draggable="false"
        />
      </div>
    </div>
    <MissionTotalProgress
      v-if="habitMissionBraindates.length"
      class="missionProgress"
      :progress="getMissionProgress(mission.id)"
      :team-progress="getMissionProgress(mission.id)"
    />
    <div v-if="habitMissionBraindates.length" class="missionDayCount">
      {{ getMissionDayCount(mission.id) + $t('of') + getTotalMissionDays(mission.id) + $t('days') }}
    </div>
  </div>
</template>

<translations>
  of: ' of '
  of_no: ' av '
  days: ' days'
  days_no: ' dager'
</translations>

<script>
import MissionTotalProgress from './MissionTotalProgress'
const habitBadgeImages = require.context('./assets/images', true, /\.(png)$/)

export default {
  components: {
    MissionTotalProgress,
  },
  props: {
    mission: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    viewingMissionId: {
      type: String,
    },
  },
  emits: ['select'],
  computed: {
    habitMissionBraindates() {
      return this.$store.getters['moduleApp/getHabitBraindates']
    },
    activeMissions() {
      return this.$store.state.moduleApp.memolife.selectedHabitMissions
    },
    language() {
      return this.$store.getters['moduleApp/locale']
    },
  },
  methods: {
    isNeuron1Active(missionId) {
      return (
        (this.getMissionDayCount(missionId) > 6 && this.getMissionDayCount(missionId) <= 22) ||
        (this.getMissionDayCount(missionId) > 28 && this.getMissionDayCount(missionId) <= 44) ||
        this.getMissionDayCount(missionId) > 50
      )
    },
    isNeuron2Active(missionId) {
      return (
        (this.getMissionDayCount(missionId) > 14 && this.getMissionDayCount(missionId) <= 22) ||
        (this.getMissionDayCount(missionId) > 36 && this.getMissionDayCount(missionId) <= 44) ||
        this.getMissionDayCount(missionId) > 58
      )
    },
    isNeuron3Active(missionId) {
      return (
        this.getMissionDayCount(missionId) === 22 ||
        this.getMissionDayCount(missionId) === 44 ||
        this.getMissionDayCount(missionId) >= 66
      )
    },
    getMissionDayCount(missionId) {
      const mission = this.activeMissions.find(mission => mission.missionId === missionId)
      return mission?.days.length ?? 0
    },
    getTotalMissionDays(missionId) {
      if (this.getMissionDayCount(missionId) <= 22) {
        return 22
      } else if (this.getMissionDayCount(missionId) > 22 && this.getMissionDayCount(missionId) <= 44) {
        return 44
      } else if (this.getMissionDayCount(missionId) > 44) {
        return 66
      }
    },
    getMissionProgress(missionId) {
      const dayCount = this.getMissionDayCount(missionId)
      if (this.getMissionDayCount(missionId) <= 22) {
        return Math.floor((dayCount / 22) * 100)
      } else if (this.getMissionDayCount(missionId) > 22 && this.getMissionDayCount(missionId) <= 44) {
        return Math.floor(((dayCount - 22) / 22) * 100)
      } else if (this.getMissionDayCount(missionId) > 44) {
        return Math.floor(((dayCount - 44) / 22) * 100)
      }
    },
    getMissionBadgeImage(missionId) {
      if (this.language === 'no') {
        return habitBadgeImages(`./${missionId}_no.png`)
      } else {
        return habitBadgeImages(`./${missionId}.png`)
      }
    },
    getMissionNeuronImage(missionId) {
      const dayCount = this.getMissionDayCount(missionId)
      if (dayCount <= 22) {
        return require('./assets/images/neuron-bronze.png')
      } else if (dayCount > 22 && dayCount <= 44) {
        return require('./assets/images/neuron-silver.png')
      } else if (dayCount > 44 && dayCount <= 66) {
        return require('./assets/images/neuron-gold.png')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$camelcase-spacing: 0.02em;
$text-font: Source Sans Pro;
$color-white: #d6d4ef;

.HabitMissionItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #26216f62;
  margin: 0.5em;
  margin-bottom: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  border-radius: 0.8em;
  opacity: 0.4;
  border: 2px solid rgba(169, 111, 255, 0);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  transition: all 0.2s ease-in;

  @media only screen and (max-width: 600px) {
    margin: 2vw;
    margin-bottom: 2vw;
    padding-top: 4vw;
    padding-bottom: 4vw;
    border-radius: 4vw;
  }

  &.selected {
    opacity: 1;
  }
  &.viewing {
    opacity: 1;
    border: 2px solid rgba(111, 111, 255, 0.544);
  }
  &.notSelected {
  }

  &:hover {
    cursor: pointer;
    position: relative;
    opacity: 1;
    transform: translateY(-6px);
  }
}

.badgeContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .missionSunImage {
    height: 4em;

    @media only screen and (max-width: 600px) {
      display: flex;
      height: 13vw;
    }
    @media only screen and (min-width: 600px) and (max-width: 1200px) {
      height: 7.5vw;
    }
  }

  //   .missionSelectedImage {
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     opacity: 0;

  //     &.show {
  //       opacity: 1;
  //     }
  //   }
}
.missionImage {
  width: auto;
  height: 11em;
  margin-top: 0.7em;
  margin-bottom: 0.5em;
  padding-right: 1em;
  padding-left: 1.3em;
  pointer-events: none;

  @media only screen and (max-width: 600px) {
    height: 40vw;
    margin-top: 3vw;
    margin-bottom: 3vw;
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

.missionTitle {
  font-size: 1.15em;
  font-weight: 600;
  color: white;
  letter-spacing: $camelcase-spacing;
  font-family: $text-font;
  margin-bottom: 0.5em;
  margin-top: 0;

  @media only screen and (max-width: 600px) {
    font-size: 4.7vw;
    margin-bottom: 2vw;
  }
}

.neurons {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-bottom: 2.5em;

  @media only screen and (max-width: 600px) {
    margin-bottom: 11vw;
  }
}

.neuronImage {
  width: 1.3em;
  height: auto;
  margin: 0 0.2em;
  opacity: 0.4;

  @media only screen and (max-width: 600px) {
    width: 4.5vw;
    margin: -2vw 0.5vw;
  }

  &.active {
    opacity: 1;
  }
}

.missionBottomText {
  color: $color-white;
  letter-spacing: $camelcase-spacing;
  font-family: $text-font;
  margin-top: 0.5em;

  @media only screen and (max-width: 600px) {
    font-size: 4vw;
    margin-top: 1vw;
  }
}

.missionDayCount {
  font-size: 1em;
  font-weight: 400;
  margin-top: 0.5em;

  color: $color-white;

  @media only screen and (max-width: 600px) {
    font-size: 3.8vw;
    margin-top: 2vw;
  }
}

.missionProgress {
  width: 70%;
  margin-top: 0.3em;
}
</style>
