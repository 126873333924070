<template>
  <div class="TeamProfileLeaderboard">
    <div class="columnName">
      <div class="columnRankUsersContainer">
        <p class="rank">RANK</p>
        <p>{{ $t('member') }}</p>
      </div>
      <p>{{ $t('lifepoints') }}</p>
    </div>

    <div class="tableContainer">
      <table class="leaderboardTable">
        <tbody>
          <tr v-for="(member, rank) of membersList" :key="member.id">
            <div class="tableRowContainer">
              <div class="rankNameContainer">
                <td class="rankNum">{{ rank + 1 }}</td>
                <Avatar class="avatar" :avatar="member.avatar" />
                <td>{{ member.displayName }}</td>
              </div>
              <div class="braindatesContainer">
                <img class="braindateIcon" src="@/assets/images/leaderboards/lifepointsIcon.svg" alt="" />
                <td class="braindatesCount">{{ member.braindates + member.crystals }}</td>
              </div>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<translations>
    lifepoints: 'LIFE POINTS'
    lifepoints_no: 'LIVSPOENG'
    braindates: BRAIN DATES
    braindates_no: HJERNEDATES
    member: MEMBER
    member_no: MEDLEM

</translations>

<script>
import Avatar from '@/components/Avatar'

export default {
  components: { Avatar },

  props: {
    membersList: {
      type: Array,
      required: true,
    },
  },

  computed: {
    top3MembersList() {
      return this.membersList.slice(0, 3)
    },
  },
}
</script>

<style lang="scss" scoped>
.TeamProfileLeaderboard {
  margin-bottom: 4em;
}

.leaderboardTable {
  width: 100%;
  // width: 50em;

  // @media (max-width: 620px) {
  //   width: 95vw;
  // }
}
.columnName {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #a4b1cd88;
  font-weight: 500;
  font-size: 0.8em;
  text-align: left;

  .columnRankUsersContainer {
    display: flex;
    flex-direction: row;

    .rank {
      width: 4.5em;
    }
  }
}

.tableRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1em 0;
  border-bottom: 1px solid #a4b1cd2a;
  overflow: auto;

  .rankNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .avatar {
      height: 1.9em;
      width: 1.9em;
      margin-right: 1.5em;
    }
    .rankNum {
      width: 2.4em;
    }
  }
  .braindatesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .braindateIcon {
      width: 1.3em;
    }
    .braindatesCount {
      text-align: end;
      margin-left: 0.5em;
    }
  }
}
</style>
