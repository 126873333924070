<template>
  <div
    class="SkillsMissionItem"
    :class="{
      selected,
      viewing,
      notSelected,
    }"
  >
    <img class="missionImage" :src="getMissionBadgeImage()" alt="" />
    <!-- TODO: only show if activeMission -->
    <MissionTotalProgress class="missionTotalProgress" :progress="progress" :team-progress="progress" />
    <slot></slot>
  </div>
</template>

<script>
import MissionTotalProgress from './MissionTotalProgress'
const missionSectionImages = require.context('./assets/images/', true, /\.(svg)$/)

export default {
  components: {
    MissionTotalProgress,
  },
  props: {
    mission: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    viewing: {
      type: Boolean,
      default: false,
    },
    notSelected: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: Number,
    },
  },
  computed: {
    progress() {
      if (this.teamId) {
        if (this.$store.state.moduleTeam.teamId !== this.teamId) {
          return 0
        }
        return Math.floor(this.$store.getters['moduleTeam/getLifeskillsMissionStats'].progress * 100)
      }

      const lifeskillIds = this.mission.lifeskills
      var missionProgress = 0
      for (const i in lifeskillIds) {
        const stats = this.$store.getters['moduleApp/getLifeskillStats'](lifeskillIds[i])
        missionProgress = missionProgress + stats.progress
      }
      return Math.floor((missionProgress / lifeskillIds.length) * 100)
    },
  },
  methods: {
    getMissionBadgeImage() {
      return missionSectionImages(`./${this.mission.id}.svg`)
    },
  },
}
</script>

<style lang="scss" scoped>
.SkillsMissionItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #26216f62;
  margin: 1em;
  margin-top: 0.6em;
  margin-left: 0em;
  padding: 0 1.5em;
  // width: 100%;
  border-radius: 0.6em;
  opacity: 0.3;
  border: 2px solid rgba(111, 111, 255, 0);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  transition: all 0.2s ease-in;

  @media only screen and (max-width: 600px) {
    margin-top: 2vw;
    margin-bottom: 2vw;
    margin-left: 0;
    padding-left: 6vw;
    padding-right: 6vw;
    padding-top: 0vw;
    // width: 50vw;

    border-radius: 3vw;
  }

  &.selected {
    opacity: 1;
  }
  &.viewing {
    opacity: 1;
    border: 2px solid rgba(111, 111, 255, 0.544);
  }
  &.notSelected {
    // background-color: transparent;
    padding: 0 1em;
  }

  &:hover {
    cursor: pointer;
    position: relative;
    opacity: 1;
    transform: translateY(-6px);
  }
}

//   .selectMissionButton {
//     position: absolute;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     border-radius: 0.3em;
//     background: #9daef791;
//     font-weight: 600;
//     color: rgb(0, 0, 0);
//     visibility: hidden;
//     width: 10em;
//     height: 3em;

//     &.show {
//       visibility: visible;
//     }
//     &.selected {
//       background: #92f1f6;
//       color: rgba(0, 0, 0, 0.908);
//     }
//   }
// }

.missionTotalProgress {
  padding-bottom: 2em;
  @media only screen and (max-width: 600px) {
    padding-bottom: 5vw;
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

.missionImage {
  height: 10.8em;
  width: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  pointer-events: none;

  @media only screen and (max-width: 600px) {
    height: 39vw;
    margin-top: 2vw;
    margin-bottom: 5vw;
  }
}
</style>
