<template>
  <div class="HabitMissionDetails">
    <!-- <div class="missionInfo"> -->
    <div class="titleContainer">
      <div class="infoTitle">{{ language === 'no' ? mission.name_no : mission.name }}</div>
      <slot name="button"> </slot>
    </div>
    <div class="textContainer">
      <div class="infoText">
        {{ getMissionInfoText() }}
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<translations>
  title: 'Choose a mission for your brain dates: '
  title_no: 'Velg et oppdrag for dine hjernedates:'
  select: 'Select'
  select_no: 'Velg'
  deselect: 'Deselect'
  deselect_no: 'Velg bort'

  mentalHygiene: 'Mental   Hygiene'
  mentalHygiene_no: 'Mentalhygiene'

  memoryGames: 'Memory Games'
  memoryGames_no: 'Huskespill'

  3min: '3 minutes a day'
  3min_no: '3 minutter om dagen'

  HabitsTitle: 'Habit Missions'
  HabitsTitle_no: 'Oppdrag: Vaner'
    

  joysInfoText: 'This science based «happiness button» in your brain also increases productivity and even sales results with more than 30%.'
  joysInfoText_no: 'Denne vitenskapsbaserte «lykkeknappen» i hjernen din øker også produktiviteten og til og med salgsresultater med mer enn 30%.'
  frogInfoText: 'Enjoy the bliss of completing the things you don’t feel like doing. Kiss your frogs and make your day a fairytale.'
  frogInfoText_no: 'Nyt følelsen av å fullføre det du egentlig ikke føler for å gjøre. "Kyss" froskene og gjør hverdagen til et eventyr!'
  focusInfoText: '210 minutes! That’s the average of daily procrastination time. Here’s how you reclaim your brain and get focused.'
  focusInfoText_no: '210 minutter, det er gjennomsnittstiden vi bruker på å utsette ting per dag! Slik får du hjernen re fokusert og hverdagen balansert.'
  planInfoText: 'Planning tomorrow’s work today is like a spa for your brain, and a brain hack for the work-life balance we all deserve.'
  planInfoText_no: 'Det å planlegge morgendagen i dag er som et spa for hjernen, og en hjernehack for balansen mellom jobb og fritid som vi jo alle fortjener.'
  diaryInfoText: 'Give your brain a hug before you go to sleep and embrace the feeling of making every day a little bit better.'
  diaryInfoText_no: 'Gi hjernen din en god klem før du går til sengs og nyt følelsen av å gjøre hver dag litt bedre.'
  
  facesInfoText: 'You meet a lot of people at the event. Will you become the social star at the after-party or face the embarrassment of forgetting their names?'
  facesInfoText_no: 'Du møter mange mennesker på jobbmessen. Husker du hva de heter senere, eller må du lide deg gjennom flauheten av å glemme navnene deres?'
  shoppingInfoText: 'You’re on the way to the store when someone calls and asks you to bring a long list of shopping items. Have you got it in you to remember all you need?'
  shoppingInfoText_no: 'Du er på vei til butikken når noen ringer og ber deg ta med en haug av ting. Plutselig er handlelisten blitt stor. Har du det som skal til for å huske alt?'
  bankvaultInfoText: 'The safe wheel is spinning and briefly stops … before it continues. Can you remember the pin codes and avoid triggering the alarm?'
  bankvaultInfoText_no: 'Kodehjulet spinner og stopper for et lite øyeblikk ... før det spinner videre. Husker du alle tallkodene uten å utløse alarmen?'
  brainstormingInfoText: 'Your brainstorming session produces a lot of ideas. Can you remember them all?'
  brainstormingInfoText_no: 'Du får mange ideer ut av en brainstorming-sesjon. Klarer du å huske alle sammen?'
  timetravelInfoText: 'Your first time travel is a rush! Can you remember the different years and the events as you’re thrown through time?'
  timetravelInfoText_no: 'Din første tidsreise er et ordentlig kick! Klarer du å huske årstallene for de historiske hendelsene idet du kastes gjennom tiden?'
  vocabguruInfoText: 'You’re in the world of Clockwork Orange and you need to master its ‘Nadsat’ language to survive. Will you learn the meaning of its words in time?'
  vocabguruInfoText_no: "Du er i den farlige 'Clockwork Orange'-verdenen og må mestre språket Nadsat for å overleve. Har du det som skal til for å lære ordene tidsnok?"
</translations>

<script>
import { getHabitMissionById } from '@/store/modules/module-app/missions.js'

export default {
  props: {
    missionId: {
      type: String,
      required: true,
    },
  },
  computed: {
    mission() {
      return getHabitMissionById(this.missionId)
    },
    language() {
      return this.$store.getters['moduleApp/locale']
    },
  },
  methods: {
    getMissionInfoText() {
      if (this.missionId === 'daily-joys') {
        return this.$t('joysInfoText')
      } else if (this.missionId === 'kiss-the-frog') {
        return this.$t('frogInfoText')
      } else if (this.missionId === 'focus-check') {
        return this.$t('focusInfoText')
      } else if (this.missionId === 'daily-plan') {
        return this.$t('planInfoText')
      } else if (this.missionId === 'learning-diary') {
        return this.$t('diaryInfoText')
      } else if (this.missionId === 'faces') {
        return this.$t('facesInfoText')
      } else if (this.missionId === 'shopping') {
        return this.$t('shoppingInfoText')
      } else if (this.missionId === 'bankvault') {
        return this.$t('bankvaultInfoText')
      } else if (this.missionId === 'brainstorm') {
        return this.$t('brainstormingInfoText')
      } else if (this.missionId === 'timemachine') {
        return this.$t('timetravelInfoText')
      } else if (this.missionId === 'vocabguru') {
        return this.$t('vocabguruInfoText')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro');

$title-font: 'Raleway', sans-serif;
$text-font: Source Sans Pro;
$camelcase-spacing: 0.02em;
$color-white: #d6d4ef;

.HabitMissionDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2em;
  color: #d6d4ef;

  @media only screen and (max-width: 600px) {
    margin-top: 6vw;
  }

  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #1f1b5ad4;
    width: 40em;
    padding: 2em;
    border-radius: 0.5em;

    @media only screen and (max-width: 600px) {
      width: 95vw;
      padding: 6vw;
      border-radius: 2vw;
    }

    .infoTitle {
      font-size: 1.4em;
      font-weight: 600;
      display: flex;
      justify-content: center;
      text-align: center;
      font-family: 'Raleway', sans-serif;
      color: #d6d4ef;

      @media only screen and (max-width: 600px) {
        font-size: 4.5vw;
      }
    }
  }
}

.textContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #1f1b5ad4;
  width: 40em;
  padding: 2.5em 2em;
  margin-top: 1em;
  border-radius: 0.5em;
  margin-bottom: 3em;

  @media only screen and (max-width: 600px) {
    width: 95vw;
    padding: 8vw 5vw;
    border-radius: 2vw;
  }

  .infoText {
    font-family: $text-font;
    font-size: 1.1em;
    letter-spacing: $camelcase-spacing;
    font-weight: 500;

    @media only screen and (max-width: 600px) {
      font-size: 4vw;
    }
  }
}

.selectMissionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3em;
  background: #92f1f6;
  font-weight: 600;
  color: rgb(0, 0, 0);
  width: 10em;
  height: 3em;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    width: 30vw;
    height: 12vw;
    border-radius: 1.5vw;
  }

  &.selected {
    background: #9daef791;
    color: rgba(0, 0, 0, 0.908);
  }
}
</style>
